<template>
  <HostLayoutContainer class="banner">
    <HostLayoutItem :style="{ paddingLeft:'23px',paddingRight:'23px',paddingTop:'108px',paddingBottom:'140px'}">
      <img src="/assets/product/otherProduct_3@3x.png" alt="">
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<style lang="scss" scoped>
.banner {
  height: 716px;
  background-image: url("/assets/product/platform_bg@3x.png");
}
</style>
