<template>
  <HostLayoutContainer class="banner" :style="{ height: '835px' }">
    <HostLayoutItem>
      <TitleWithSub :title="title" id="cloud-platform"/>
      <div class="cloud-platform_img">
        <img src="/assets/product/platform@3x.png" alt="" />
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithSub from '@/components/title/title-with-sub.vue'

export default {
  components: {
    TitleWithSub,
  },
  setup () {
    return {
      title: { marginBottom: '80px', marginTop: '38px', text: '云管理平台', subText: '一站式管理云计算资源解决方案-多云管理平台' },
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  background-image: url("/assets/product/platform_bg@3x.png");
}
.cloud-platform_img {
  width: 1000px;
  height: 598px;
  margin: auto;
}
</style>
